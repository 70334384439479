import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { SavePointService } from '../save-point.service';
import { MatButtonModule } from '@angular/material/button';

export interface Groups {
  value: number;
  viewValue: number;
}

export interface Tasks {
  value: number;
  viewValue: number;
}

@Component({
  selector: 'app-app-welcome',
  templateUrl: './app-welcome.component.html',
  styleUrls: ['./app-welcome.component.css']
})

export class AppWelcomeComponent implements OnInit {

  groups: Groups[] = [];
  tasks: Tasks[] = [];

  constructor(private router: Router, public savepoint: SavePointService) {
    this.savepoint.loadCustomInformation();
  }

  ngOnInit() {
    for (let i = 2; i <= 10; i++) {
      this.groups.push({ value: i, viewValue: i });
    }
    for (let j = 2; j <= 15; j++) {
      this.tasks.push({ value: j, viewValue: j });
    }
  }

  saveInfo() {
    this.savepoint.save();
    if (this.savepoint.taskselected >= this.savepoint.groupselected) {
      this.router.navigateByUrl('/groupnames');
      return true;
    }
  }
}
