import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { SavePointService } from '../save-point.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ThrowStmt } from '@angular/compiler';
import { Task } from 'src/algorithm/task';
import { PriorityMatrix } from 'src/algorithm/priorityMatrix';

@Component({
  selector: 'app-app-groups',
  templateUrl: './app-groups.component.html',
  styleUrls: ['./app-groups.component.css']
})

export class AppGroupsComponent implements OnInit {

  groupTaskNameList: Task[] = [];
  prioritizedTaskNameList: Task[] = [];
  newPriorityMatrix = new PriorityMatrix();
  currentGroupname: string = '';
  storageKey : string = '';
  priorities: Task[] = [];

  constructor(private router: Router, private savepoint: SavePointService) {
    this.newPriorityMatrix.ensureSize(this.savepoint.groupselected, this.savepoint.taskselected);
    this.repeatTheLoading();
  }

  repeatTheLoading() {
    if (this.savepoint.wishedTasks.groupList[this.savepoint.currentGroup].name != undefined) {
      this.currentGroupname = this.savepoint.wishedTasks.groupList[this.savepoint.currentGroup].name;
    }
    this.storageKey = this.currentGroupname+".priorities";
    this.loadSavedPriorities(); 
  }

  loadSavedPriorities() {
    this.priorities = this.savepoint.loadPriorities(this.storageKey);
    if (this.priorities != undefined && this.priorities.length == this.savepoint.taskselected) {
      this.prioritizedTaskNameList = this.priorities;
    }
    else {
      let currentTaskList : Task[] = [];
      for (let item of this.savepoint.wishedTasks.taskList) {
        currentTaskList.push(item);
      }
      this.groupTaskNameList = currentTaskList;
      this.prioritizedTaskNameList = [];
    }
  }

  ngOnInit() { }

  drop(event: CdkDragDrop<Task[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  setValuesForNewPriorityMatrix() {
    for (let currentPriority = 0; currentPriority < this.savepoint.taskselected; currentPriority++) {
      let pGroupIndex = this.savepoint.currentGroup;
      let pPriorityIndex = currentPriority;
      let pTaskIndex = this.savepoint.wishedTasks.taskList.indexOf(this.prioritizedTaskNameList[currentPriority]);
      if (this.priorities != undefined) {
        pTaskIndex = this.savepoint.wishedTasks.checkIfTaskListContainsContent(this.prioritizedTaskNameList[currentPriority].name);
      }
      this.newPriorityMatrix.setPriority(pGroupIndex, pTaskIndex, pPriorityIndex);
    }
  }

  setPriorities() {
    if (this.savepoint.leftGroups != 0 && this.savepoint.currentGroup < this.savepoint.groupselected) {
      this.savepoint.leftGroups -= 1;
      this.setValuesForNewPriorityMatrix();
      this.savepoint.savePriorities(this.storageKey, this.prioritizedTaskNameList);
      this.savepoint.currentGroup += 1;
      this.repeatTheLoading();
      this.router.navigateByUrl('/groups');
    } else {
      this.setValuesForNewPriorityMatrix();
      this.savepoint.savePriorities(this.storageKey, this.prioritizedTaskNameList);
      this.savepoint.wishedTasks.overwritePriorityMatrix(this.newPriorityMatrix);
      //this.savepoint.wishedTasks.priorityMatrix.print();
      this.savepoint.currentGroup = 0;
      this.router.navigateByUrl('/control');
    }
  }
}