import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { SavePointService } from '../save-point.service';
import { CommentStmt } from '@angular/compiler';
import { Task } from '../../algorithm/task';

@Component({
  selector: 'app-app-tasknames',
  templateUrl: './app-tasknames.component.html',
  styleUrls: ['./app-tasknames.component.css']
})

export class AppTasksComponent implements OnInit {

  constructor(private router: Router, private savepoint: SavePointService) {
    this.savepoint.loadCustomInformation();
  }

  ngOnInit() {}

  calculateGroups() {
    this.savepoint.leftGroups = this.savepoint.wishedTasks.groupList.length - 1;
    this.savepoint.currentGroup = 0;
  }

  saveTasknames() {
    this.calculateGroups();
    var list = document.getElementsByClassName('inputTaskName');
    let newTaskList : Task[] = [];
    for (let i = 0; i < list.length; i++) {
      let name: string = (<HTMLInputElement>list.item(i)).value;
      newTaskList.push(new Task(name));
    }
    this.savepoint.wishedTasks.overwriteTaskList(newTaskList);
    this.savepoint.save();
    this.router.navigateByUrl('/groups');
  }
}