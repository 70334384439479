import { WishedTasks } from './wishedTasks';
import { ResultList } from './resultList';
import { PriorityMatrix } from './priorityMatrix';
import { OneMappingResult } from './oneMappingResult';
import { CurrentStep } from './currentStep';

export class MatchSolver {

    _resultSteps: CurrentStep[];

    constructor(private inputByWishedTasks: WishedTasks) {
    }

    solve() {
        const originPM = this.inputByWishedTasks.getPriorityMatrix().clone();
        const result = new OneMappingResult(originPM.getTaskCount());
        const cs = new CurrentStep(originPM, result);
        const csList: CurrentStep[] = [];

        // insert empty current step
        csList.push(cs);

        const columnIdx = -1;
        const taskNr = 0;

        this._resultSteps = this.recursiveSolve(
            originPM,
            columnIdx,
            taskNr,
            csList
            );

    }

    recursiveSolve(
        originPM: PriorityMatrix,
        columnIdx: number,
        taskNr: number,
        csList: CurrentStep[]
        ): CurrentStep[] {

        // check if ready
        let allDone = true;
        for (const cs of csList) {
            if (!cs.result.isResultComplete()) {
                allDone = false;
                continue;
            }
        }
        if (allDone) {
            return csList;
        }

        // continue
        if (columnIdx === originPM.getPriorityColumnCount()) {
            // priority matrix completely processed
            return csList;
        } else if (columnIdx === -1 || taskNr === originPM.getTaskCount()) {
            // process next column
            columnIdx += 1;
            taskNr = 0;
        } else {
            const nextStepList: CurrentStep[] = [];
            // iterate over all last steps and combine them with the next step
            for (const lastStep of csList) {
                if (lastStep.result.isResultComplete()) {
                    // result is already ready
                    nextStepList.push(lastStep);
                } else {
                    // get array with arrays
                    // the first array uses the taskNr as index.
                    // the inner array contains groupNr and the index has no meaning
                    const taskNr2groups = lastStep.priorityMatrix.getTaskListSelectedByGroupsInPriorityColumn(columnIdx);
                    if (taskNr2groups[taskNr].length === 0) {
                        // if no group is interested in the task -> continue processing with last step
                        nextStepList.push(lastStep);
                    } else {
                        // tslint:disable-next-line:prefer-for-of
                        for (let grpIdx = 0; grpIdx < taskNr2groups[taskNr].length; grpIdx++) {
                            // clone last step result
                            const cs = lastStep.clone();

                            // set one combination of GroupNr->TaskNr to the result of the current step
                            const grpNr = taskNr2groups[taskNr][grpIdx];
                            cs.result.setTaskNr(grpNr, taskNr);
                            cs.priorityMatrix.delete(grpNr, taskNr);

                            // push the result of the current step
                            nextStepList.push(cs);
                        }
                    }
                }
            }

            // continue with next task in same column
            csList = nextStepList;
            taskNr += 1;
        }

        // call method recursively
        csList = this.recursiveSolve(originPM, columnIdx, taskNr, csList);

        // find minimum value of all solutions
        let min = Number.MAX_VALUE;
        for (const csR of csList) {
            if (csR.result.isResultComplete()) {
                const cv = originPM.getPointWeight(csR.result);
                if (cv < min) {
                    min = cv;
                }
            }
        }

        // propagate only results with minimal value
        const filteredStepList: CurrentStep[] = [];
        for (const csR of csList) {
            if (csR.result.isResultComplete()) {
                const cv = originPM.getPointWeight(csR.result);
                if (cv === min) {
                    filteredStepList.push(csR);
                }
            } else {
                filteredStepList.push(csR);
            }
        }
        return filteredStepList;
    }

    get resultSteps(): CurrentStep[] {
        return this._resultSteps;
    }
}
