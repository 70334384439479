import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { SavePointService } from '../save-point.service';
import { MatButtonModule } from '@angular/material/button';
import { Task } from 'src/algorithm/task';
import { CurrentStep } from 'src/algorithm/currentStep';
import { OneMappingResult } from 'src/algorithm/oneMappingResult';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatExpansionPanel} from '@angular/material/expansion';

@Component({
  selector: 'app-app-finish',
  templateUrl: './app-finish.component.html',
  styleUrls: ['./app-finish.component.css']
})

export class AppFinishComponent implements OnInit {

  randomResult : number = 0;
  alternativeResults : CurrentStep[] = [];
  alternativesExist: boolean = false;

  constructor(private router: Router, private savepoint: SavePointService) {
    this.getRandomNumberForResult();
    this.createAlternativeResults();
    if (this.alternativeResults.length > 1) {
      this.alternativesExist = true;
    }
  }

  getRandomNumberForResult() {
    let maxNumber = this.savepoint.results.length;
    if (maxNumber != 1) {
      let random = Math.floor(Math.random() * maxNumber);
      this.randomResult = random;
    }
  }

  giveTaskName(groupidx: number) : string {
    return this.savepoint.wishedTasks.taskList[this.savepoint.results[this.randomResult].result.getTaskNr(groupidx)].name
  }

  createAlternativeResults(){
    this.alternativeResults = this.savepoint.results;
    if (this.alternativeResults.length > 1) {
      //this.alternativeResults = this.alternativeResults.splice(this.randomResult, 1);
    }
  }

  ngOnInit() {
  }
}