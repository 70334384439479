import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { SavePointService } from '../save-point.service';
import { Group } from '../../algorithm/group';

@Component({
  selector: 'app-app-groupnames',
  templateUrl: './app-groupnames.component.html',
  styleUrls: ['./app-groupnames.component.css']
})

export class AppGroupnamesComponent implements OnInit {

  constructor(private router: Router, public savepoint: SavePointService) { 
    this.savepoint.loadCustomInformation();
  }

  ngOnInit() {
  }

  saveGroupnames() {
    var list = document.getElementsByClassName('inputGroupName');
    let newGroupList : Group[] = [];
    for (let i = 0; i < list.length; i++) {
      let name: string = (<HTMLInputElement>list.item(i)).value;
      newGroupList.push(new Group(name));
    }
    this.savepoint.wishedTasks.overwriteGroupList(newGroupList);
    this.savepoint.save();
    this.router.navigateByUrl('/tasks');
  }
}