
export class OneMappingResult {

    private groupNr2TaskNr: number[] = [];

    constructor(groupCount: number) {
        for (let i = 0; i < groupCount; i++) {
            this.groupNr2TaskNr.push(-1);
        }
    }

    setTaskNr(groupNr: number, taskNr: number) {
        this.groupNr2TaskNr[groupNr] = taskNr;
    }

    getTaskNr(groupNr: number): number {
        return this.groupNr2TaskNr[groupNr];
    }

    /**
     * Traverses the directory from groupNr to taskNr
     * and tests whether the mapping is complete.
     *
     * @return  Returns true, if each group has a taskNr.
     *          Returns false, if at least one group has no taksNr (= -1).
     */
    isResultComplete(): boolean {
        for (const taskNr of this.groupNr2TaskNr) {
            if (taskNr === -1) {
                return false;
            }
        }
        return true;
    }

    clone(): OneMappingResult {
        const result = new OneMappingResult(this.groupNr2TaskNr.length);
        for (let i = 0; i < this.groupNr2TaskNr.length; i++) {
            result.groupNr2TaskNr[i] = this.groupNr2TaskNr[i];
        }
        return result;
    }

}
