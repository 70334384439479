import { Task } from './task';
import { Group } from './group';
import { PriorityMatrix } from './priorityMatrix';

export class WishedTasks {

    private _taskList: Task[] = [];
    private _groupList: Group[] = [];
    private _priorityMatrix : PriorityMatrix = new PriorityMatrix();
    
    constructor() { }

    get taskList() {
        return this._taskList;
    }

    get groupList() {
        return this._groupList;
    }

    get priorityMatrix() {
        return this._priorityMatrix;
    }

    getTasksOfGroup(groupindex: number) : Task[] {
        const theseTasks4Priority = this._priorityMatrix.getRow(groupindex);
        const tasksOfThisGroup : Task[] = [];
        for (let i = 0; i < theseTasks4Priority.length; i++) {
            tasksOfThisGroup.push(this.getTask(theseTasks4Priority[i]));
        }
        return tasksOfThisGroup;
    }

    public overwritePriorityMatrix(pPriorityMatrix : PriorityMatrix) {
        this._priorityMatrix.setNewValuesOfPriorityMatrix(pPriorityMatrix);
    }

    public addGroup(g: Group): number {
        const n = this._groupList.push(g);
        this._priorityMatrix.ensureSize(this._groupList.length, this._taskList.length);
        return n;
    }

    public overwriteGroupList(pGroupList: Group[]) {
        if (pGroupList != undefined) {
            this._groupList = [];
            for (let g = 0; g < pGroupList.length; g++) {
                this.addGroup(new Group(pGroupList[g].name));
            }
        }
    }

    public checkIfTaskListContainsContent(pName: string) : number {
        for (let index = 0; index < this.taskList.length; index++) {
            if (this.taskList[index].name === pName) {
                return index;
            }
        }
    }

    public overwriteTaskList(pTaskList: Task[]) {
        if (pTaskList != undefined) {
            this._taskList = [];
            for (let t = 0; t < pTaskList.length; t++) {
                this.addTask(new Task(pTaskList[t].name));
            }
        }
    }

    addTask(t: Task): number {
        const n = this._taskList.push(t);
        this._priorityMatrix.ensureSize(this._groupList.length, this._taskList.length);
        return n;
    }

    removeTask(t: Task) {
        const index = this._taskList.indexOf(t, 0);
        if (index > -1) {
            this._taskList.splice(index, 1);
        }
        this._priorityMatrix.ensureSize(this._groupList.length, this._taskList.length);
    }

    getTask(index: number): Task {
        return this._taskList[index];
    }

    getTaskCount(): number {
        return this._taskList.length;
    }

    removeGroup(g: Group) {
        const index = this._groupList.indexOf(g, 0);
        if (index > -1) {
            this._groupList.splice(index, 1);
        }
        this._priorityMatrix.ensureSize(this._groupList.length, this._taskList.length);
    }

    getGroup(index: number): string {
        return this._groupList[index].name;
    }

    getGroupCount(): number {
        return this._groupList.length;
    }

    getPriorityMatrix(): PriorityMatrix {
        return this.priorityMatrix;
    }

}
