export class Task {

    private _count = 0;

    private _name: string;

    constructor(name: string) {
        this._name = name;
    }

    public getName(): string {
        return this.name;
    }

    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    public resetCount(): void {
        this._count = 0;
    }
    public getCount(): number {
        return this._count;
    }
    public addToCount(pCount: number) {
        this._count = this._count + pCount;
    }
}
