import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGroupnamesComponent } from './app-groupnames/app-groupnames.component';
import{ AppTasksComponent } from './app-tasknames/app-tasknames.component';
import{ AppWelcomeComponent } from './app-welcome/app-welcome.component';
import { AppGroupsComponent } from './app-groups/app-groups.component';
import { AppControlComponent } from './app-control/app-control.component';
import { AppFinishComponent } from './app-finish/app-finish.component';

const routes: Routes = [
  { path: 'welcome', component: AppWelcomeComponent },
  { path: 'groupnames', component: AppGroupnamesComponent },
  { path: 'tasks', component: AppTasksComponent },
  { path: 'groups', component: AppGroupsComponent },
  { path: 'control', component: AppControlComponent },
  { path: 'finish', component: AppFinishComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
