import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { SavePointService } from '../save-point.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Task } from 'src/algorithm/task';
import { PriorityMatrix } from 'src/algorithm/priorityMatrix';

@Component({
  selector: 'app-app-control',
  templateUrl: './app-control.component.html',
  styleUrls: ['./app-control.component.css']
})

export class AppControlComponent implements OnInit {

  moveable : PriorityMatrix;

  constructor(private router: Router, private savepoint: SavePointService) {
    this.moveable = this.savepoint.wishedTasks.priorityMatrix.clone();
  }

  ngOnInit() {}

  checkDefined(pTask: Task): String {
    let toReturn = " - ";
    if (pTask != undefined) {
      return pTask.getName();
    }
    return toReturn;
  }

  drop(event: CdkDragDrop<string[]>, groupIdx: number) {
    this.savepoint.wishedTasks.priorityMatrix.changePriority(groupIdx, event.previousIndex, event.currentIndex);
    console.log(event);
  }

  setResults() {
    this.savepoint.calculateResults();
    this.router.navigateByUrl('/finish');
  }

}
