import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { SavePointService } from './save-point.service';
import { WishedTasks } from 'src/algorithm/wishedTasks';
import { MatchSolver } from 'src/algorithm/matchSolver';
import { PriorityMatrix } from 'src/algorithm/priorityMatrix';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'MatchMaker';

  constructor(private router: Router, private savepoint: SavePointService) { }

  ngOnInit() {
  }

  startAnew() {
    this.savepoint.wishedTasks = new WishedTasks();
    localStorage.clear();
    this.start();
  }

  start() {
    this.savepoint.wishedTasks.overwritePriorityMatrix(new PriorityMatrix);
    this.savepoint.matchSolver = new MatchSolver(this.savepoint.wishedTasks);
    this.router.navigateByUrl('/welcome');
  }
}