import { Injectable } from '@angular/core';
import { Task } from '../algorithm/task';
import { Group } from '../algorithm/group';
import { WishedTasks } from 'src/algorithm/wishedTasks';
import { MatchSolver } from 'src/algorithm/matchSolver';
import { PriorityMatrix }  from 'src/algorithm/priorityMatrix';
import { CurrentStep } from 'src/algorithm/currentStep';

@Injectable({
  providedIn: 'root'
})
export class SavePointService {

  public wishedTasks = new WishedTasks();
  public matchSolver = new MatchSolver(this.wishedTasks);

  groupselected: number;
  taskselected: number;

  public priorityList: Task[][] = [];
  
  public currentGroup: number = 0;
  public leftGroups: number;

  public results : CurrentStep[] = [];

  constructor() {
    this.wishedTasks.priorityMatrix.ensureSize(this.groupselected, this.taskselected);
  }

  createArray(length: number): any[] {
    return Array(length);
  }

  loadCustomInformation() {
    if (localStorage.length > 0) {
      this.groupselected = JSON.parse(localStorage.getItem('groupselected'));
      this.taskselected = JSON.parse(localStorage.getItem('taskselected'));
    } else {
      this.groupselected = 2;
      this.taskselected = 2;
    }

    const groupListLocalStorage = JSON.parse(localStorage.getItem('groupList'));
    if (groupListLocalStorage != null && groupListLocalStorage.length == this.groupselected) {
      for (let i = 0; i < this.groupselected; i++) {
      this.wishedTasks.groupList[i] = new Group(groupListLocalStorage[i]._name);
      }
    }
    else if (groupListLocalStorage == null || groupListLocalStorage.length != this.groupselected) {
      let pGroupList : Group[] = [];
      this.wishedTasks.overwriteGroupList(pGroupList);
      for (let i = 0; i < this.groupselected; i++) {
      this.wishedTasks.groupList[i] = new Group('');
      }
    }

    const taskListLocalStorage = JSON.parse(localStorage.getItem('taskList'));
    if (taskListLocalStorage != null && taskListLocalStorage.length == this.taskselected) {
      for (let i = 0; i < this.taskselected; i++) {
      this.wishedTasks.taskList[i] = new Task(taskListLocalStorage[i]._name);
      }
    }
    else if (taskListLocalStorage == null || taskListLocalStorage.length != this.taskselected) {
      let pTaskList : Task[] = [];
      this.wishedTasks.overwriteTaskList(pTaskList);
      for (let i = 0; i < this.taskselected; i++) {
      this.wishedTasks.taskList[i] = new Task('');
      }
    }
  }

  savePriorities(pKey: string, pItem: Task[]) {
    localStorage.setItem(pKey, JSON.stringify(pItem));
  }

  loadPriorities (pKey: string) : Task[] {
    let currentPriorities : Task[] = [];
    if (JSON.parse(localStorage.getItem(pKey)) != undefined) {
      for (let item of JSON.parse(localStorage.getItem(pKey))) {
        currentPriorities.push(new Task(item._name));
      }
    }
    else {
      currentPriorities = undefined;
    }
    return currentPriorities;
  }

  save() {
    localStorage.setItem('groupselected', JSON.stringify(this.groupselected));
    localStorage.setItem('taskselected', JSON.stringify(this.taskselected));
    localStorage.setItem('groupList', JSON.stringify(this.wishedTasks.groupList));
    localStorage.setItem('taskList', JSON.stringify(this.wishedTasks.taskList));
  }

  calculateResults() {
    this.matchSolver = new MatchSolver(this.wishedTasks);
    this.matchSolver.solve();
    this.results = this.matchSolver.resultSteps;
  }

  getResults() : CurrentStep[] {
    return this.results;
  }

}
