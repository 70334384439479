import { Task } from './task';
import { Group } from './group';
import { OneMappingResult } from './oneMappingResult';

export class PriorityMatrix {

    private _priorityMatrix: number[][] = [];

    constructor() { }

    ensureSize(groupCount: number, taskCount: number) {
        if (groupCount === 0 || taskCount === 0) {
            this._priorityMatrix = [];
            return;
        }

        const newPriorityMatrix: number[][] = [];

        for (let groupIndex = 0; groupIndex < groupCount; groupIndex++) {
            newPriorityMatrix[groupIndex] = [];
            for (let priorityIndex = 0; priorityIndex < taskCount; priorityIndex++) {
                let newValue = -1;
                if (typeof (this._priorityMatrix) !== 'undefined' &&
                    groupIndex < this._priorityMatrix.length &&
                    typeof (this._priorityMatrix[groupIndex]) !== 'undefined' &&
                    priorityIndex < this._priorityMatrix[groupIndex].length) {
                    newValue = this._priorityMatrix[groupIndex][priorityIndex];
                }
                newPriorityMatrix[groupIndex][priorityIndex] = newValue;
            }
        }
        this._priorityMatrix = newPriorityMatrix;
    }

    setNewValuesOfPriorityMatrix(pPriorityMatrix: PriorityMatrix) {
        this._priorityMatrix = [];
        for (let i = 0; i < pPriorityMatrix.getGroupRowCount(); i++)
        {
            this._priorityMatrix[i] = [];
            for (let j = 0; j < pPriorityMatrix.getTaskCount(); j++) {
                const v = pPriorityMatrix._priorityMatrix[i][j];
                this._priorityMatrix[i].push(v);
            }
        }
    }

    getGroupRowCount(): number {
        if (typeof (this._priorityMatrix) === 'undefined') {
            return 0;
        }
        return this._priorityMatrix.length;
    }

    getPriorityColumnCount(): number {
        if (typeof (this._priorityMatrix) === 'undefined' ||
            typeof (this._priorityMatrix[0]) === 'undefined') {
            return 0;
        }
        return this._priorityMatrix[0].length;
    }

    getTaskCount(): number {
        return this.getPriorityColumnCount();
    }

    setPriority(groupIndex: number, taskIndex: number, priority: number) {
        const oldTaskIndex = this._priorityMatrix[groupIndex][priority];
        // find taskIndex in priority list
        for (let i = 0; i < this._priorityMatrix[groupIndex].length; i++) {
            if (this._priorityMatrix[groupIndex][i] === taskIndex) {
                this._priorityMatrix[groupIndex][i] = oldTaskIndex;
            }
        }
        this._priorityMatrix[groupIndex][priority] = taskIndex;
    }

    getPriority(groupIndex: number, taskIndex: number): number {
        for (let i = 0; i < this._priorityMatrix[groupIndex].length; i++) {
            if (this._priorityMatrix[groupIndex][i] === taskIndex) {
                return i;
            }
        }
        return -1;
    }

    getTaskNr(groupIndex: number, priorityIndex: number): number {
        return this._priorityMatrix[groupIndex][priorityIndex];
    }

    getGroupListOfTask(taskIndex: number, columnIdx: number): number[] {
        const result: number[] = [];
        for (let grpIdx = 0; grpIdx < this.getGroupRowCount(); grpIdx++) {
            if (this._priorityMatrix[grpIdx][columnIdx] === taskIndex) {
                result.push(grpIdx);
            }
        }
        return result;
    }

    getPointWeight(result: OneMappingResult) {
        let sum = 0;
        for (let grpIdx = 0; grpIdx < this.getGroupRowCount(); grpIdx++) {
            const taskIdx = result.getTaskNr(grpIdx);
            const point = this.getPriority(grpIdx, taskIdx);
            sum += point;
        }
        return sum;
    }

    delete(groupIdx: number, taskIdx: number) {

        for (let grpIdx = 0; grpIdx < this.getGroupRowCount(); grpIdx++) {
            for (let priIdx = 0; priIdx < this.getPriorityColumnCount(); priIdx++) {
                if (this._priorityMatrix[grpIdx][priIdx] === taskIdx) {
                    this._priorityMatrix[grpIdx][priIdx] = -1;
                }
            }
        }
        for (let pIdx = 0; pIdx < this.getPriorityColumnCount(); pIdx++) {
            this._priorityMatrix[groupIdx][pIdx] = -1;
        }
    }


    clone(): PriorityMatrix {
        const c = new PriorityMatrix();

        c._priorityMatrix = [];
        for (const rowOri of this._priorityMatrix) {
            const rowCpy: number[] = [];
            for (const cellOri of rowOri) {
                rowCpy.push(cellOri);
            }
            c._priorityMatrix.push(rowCpy);
        }
        return c;
    }

    getColumn(priorityColumnIdx: number): number[] {
        const tasks4Priority: number[] = [];
        for (let grpIdx = 0; grpIdx < this.getGroupRowCount(); grpIdx++) {
            tasks4Priority.push(this._priorityMatrix[grpIdx][priorityColumnIdx]);
        }
        return tasks4Priority;
    }

    getRow(groupIdx: number): number[] {
        const tasks4Priority: number[] = [];
        for (let priorityIdx = 0; priorityIdx < this.getPriorityColumnCount(); priorityIdx++) {
            tasks4Priority.push(this._priorityMatrix[groupIdx][priorityIdx]);
        }
        return tasks4Priority;
    }

    changePriority(groupIdx: number, previousIdx: number, currentIdx: number) {
        const n = this._priorityMatrix[groupIdx][previousIdx];
        this._priorityMatrix[groupIdx][previousIdx] = this._priorityMatrix[groupIdx][currentIdx];
        this._priorityMatrix[groupIdx][currentIdx] = n;
    }

    /**
     * Returns an array of arrays.
     * Each row represents a task
     * (array index is equal to task number) and
     * contains an array with group numbers.
     * The group selected the task with the priority *priorityColumnIdx*.
     *
     * @param priorityColumnIdx Priority (column in the internal array, but not column in the result)
     * @return First array contains the tasks and the index is equal to the task number.
     *         Second array contains the group numbers and the index has no meaning.
     */
    getTaskListSelectedByGroupsInPriorityColumn(priorityColumnIdx: number): number[][] {
        const task2groups: number[][] = [];
        for (let i = 0; i < this.getPriorityColumnCount(); i++) {
            task2groups.push([]);
        }

        for (let grpIdx = 0; grpIdx < this.getGroupRowCount(); grpIdx++) {
            if (this._priorityMatrix[grpIdx][priorityColumnIdx] !== -1) {
                task2groups[this._priorityMatrix[grpIdx][priorityColumnIdx]].push(grpIdx);
            }
        }

        return task2groups;
    }

    print() {
        console.log(this._priorityMatrix);
    }

    getTaskCountForPriorityColumn(columnIdx: number): number[] {
        const taskCount: number[] = [];
        for (let i = 0; i < this.getPriorityColumnCount(); i++) {
            taskCount.push(0);
        }

        for (let i = 0; i < this.getGroupRowCount(); i++) {
            taskCount[this._priorityMatrix[i][columnIdx]] += 1;
        }

        return taskCount;
    }
}
