import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppGroupnamesComponent } from './app-groupnames/app-groupnames.component';
import { AppTasksComponent } from './app-tasknames/app-tasknames.component';
import { AppWelcomeComponent } from './app-welcome/app-welcome.component';
import { AppGroupsComponent } from './app-groups/app-groups.component';
import { AppControlComponent } from './app-control/app-control.component';
import { AppFinishComponent } from './app-finish/app-finish.component';
import {MatInputModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatDividerModule, MatListModule} from '@angular/material'
import { SavePointService } from './save-point.service';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  declarations: [
    AppComponent,
    AppGroupnamesComponent,
    AppTasksComponent,
    AppWelcomeComponent,
    AppGroupsComponent,
    AppControlComponent,
    AppFinishComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    DragDropModule,
    MatDividerModule,
    MatListModule,
    MatExpansionModule,
    ],
  providers: [SavePointService],
  bootstrap: [AppComponent]
})
export class AppModule { }
