import { PriorityMatrix } from './priorityMatrix';
import { OneMappingResult } from './oneMappingResult';

export class CurrentStep {

    private mResult: OneMappingResult;
    private mPriorityMatrix: PriorityMatrix;

    constructor(
        priorityMatrix: PriorityMatrix,
        mResult: OneMappingResult) {
        this.mPriorityMatrix = priorityMatrix;
        this.mResult = mResult;
    }

    get result(): OneMappingResult {
        return this.mResult;
    }

    get priorityMatrix(): PriorityMatrix {
        return this.mPriorityMatrix;
    }

    clone(): CurrentStep {
        const cs: CurrentStep = new CurrentStep(
            this.mPriorityMatrix.clone(),
            this.mResult.clone());
        return cs;
    }

}
